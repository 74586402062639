import { useEffect } from 'react'
import { useFetchProfile } from 'hooks/use-fetch-profile'
import { useTranslationLayer } from 'hooks/use-translation'
import { useAuth } from '../store/useAuth'
import api from 'utils/api'

async function refreshToken() {
  return api.get('/oauth/token')
}

export function useVerifyAuth() {
  const isLoading = useAuth(state => state.isLoading)
  const { fetch: refetchProfile } = useFetchProfile()
  const { config } = useTranslationLayer()
  const fetchData = async () => {
    try {
      await refreshToken()
    } catch (err) {}

    await refetchProfile()
  }
  useEffect(() => {
    //fetchData();
    refetchProfile()
  }, [])

  return { isLoading, refetchProfile, config }
}
