import { useEffect } from 'react'

import { useAuth } from '../store/useAuth'
import { useTranslation } from '../store/useTranslation'
import shallow from 'zustand/shallow'

export function useTranslationLayer() {
  const user = useAuth(state => state.user)
  const setTranslation = useTranslation(state => state.setTranslation)

  const { config } = useTranslation(
    state => ({
      config: state.config,
    }),
    shallow,
  )
  const initialise = () => {
    setTranslation(user?.config)
  }

  useEffect(() => {
    initialise()
  }, [user])

  return { config }
}
