export interface IRealtyBrandConfig {
  [name: string]: { metaData: { title: string } }
}

export const realtyBrandConfig: IRealtyBrandConfig = {
  realty: {
    metaData: {
      title: 'Realty.com',
    },
  },

  homescout: {
    metaData: {
      title: 'LenderPro',
    },
  },
  morganti_group: {
    metaData: {
      title: 'Rob Morganti Group',
    },
  },
  drmoves: {
    metaData: {
      title: 'DrMoves',
    },
  },
  listing_com: {
    metaData: {
      title: 'Listing.com',
    },
  },
  mellohome: {
    metaData: {
      title: 'mellohome.com',
    },
  },
  realty_ca: {
    metaData: {
      title: 'Realty.ca',
    },
  },
  exp_realty: {
    metaData: {
      title: 'EXP Realty',
    },
  },
  coachpipes: {
    metaData: {
      title: 'Coach Pipes',
    },
  },
  datahub: {
    metaData: {
      title: 'Datahub',
    },
  },
  movetube: {
    metaData: {
      title: 'MoveTube',
    },
  },
}
