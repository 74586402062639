import create from 'zustand'
import { persist } from 'zustand/middleware'

interface Settings {
  direction: 'ltr' | 'rtl'
  responsiveFontSizes?: boolean
  theme: 'light' | 'dark'
  saveSettings: (settings: Settings) => void
}

export const useSettings = create<Settings>(
  persist(
    (set, get) => ({
      direction: 'ltr',
      responsiveFontSizes: true,
      theme: 'light',
      saveSettings: (setting: Settings) =>
        set({
          direction: setting.direction,
          responsiveFontSizes: setting.responsiveFontSizes,
          theme: setting.theme,
        }),
    }),
    {
      name: 'settings',
    },
  ),
)

// mountStoreDevtool('settings', useSettings as any);
