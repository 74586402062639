import React from 'react'
import toast from 'react-hot-toast'
import { Transition } from 'react-transition-group'

import { Button, Card, Container, Typography, Box } from '@mui/material'
import {
  CheckCircleOutlined as CheckCircleIcon,
  ErrorOutline as ErrorIcon,
} from '@mui/icons-material'

const duration = 300

const defaultStyle: any = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const useToast = () => {
  const notify = ({
    title,
    description,
    maxWidth = 'xs',
  }: {
    title: string
    description: string
    maxWidth?: 'md' | 'xs'
  }) => {
    return toast.custom(t => (
      <Transition in={t.visible ? true : false} timeout={duration}>
        {state => {
          return (
            <Container
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              maxWidth={maxWidth}
            >
              <Card variant="outlined" sx={{ borderColor: 'success.main' }}>
                <Box
                  p={1.5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box pr={2}>
                      <CheckCircleIcon
                        color="success"
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                        }}
                        fontSize="small"
                      />
                    </Box>

                    <Box>
                      <Typography variant="h6">{title}</Typography>

                      <Typography>{description}</Typography>
                    </Box>
                  </Box>
                  <Button
                    onClick={() => {
                      toast.dismiss(t.id)
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Card>
            </Container>
          )
        }}
      </Transition>
    ))
  }

  const notifyError = (error: string) => {
    return toast.error(error)
  }

  const notifyValidationErrors = (
    message: string,
    errors: Record<string, string[]> | string[],
  ) => {
    return toast(t => (
      <Transition in={t.visible ? true : false} timeout={duration}>
        {state => {
          return (
            <Box display="flex" alignItems="flex-start" gap={2}>
              <ErrorIcon color="error" />
              <Box flex={1}>
                <Typography variant="subtitle1" color="error" gutterBottom>
                  {message}
                </Typography>
                <Box component="ul" sx={{ m: 0 }}>
                  {Array.isArray(errors)
                    ? errors.map(error => (
                        <Typography
                          component="li"
                          key={error}
                          variant="body2"
                          color="text.secondary"
                        >
                          {error}
                        </Typography>
                      ))
                    : Object.entries(errors).map(([field, fieldErrors]) =>
                        fieldErrors.map(error => (
                          <Typography
                            component="li"
                            key={`${field}-${error}`}
                            variant="body2"
                            color="error"
                          >
                            {error}
                          </Typography>
                        )),
                      )}
                </Box>
              </Box>
            </Box>
          )
        }}
      </Transition>
    ))
  }

  const notifySuccess = (message: string) => {
    return toast.success(message)
  }

  return { notify, notifyError, notifySuccess, notifyValidationErrors }
}
export default useToast
