import { Member } from 'types/members'

import create from 'zustand'

interface UserState {
  isAuthenticated?: boolean
  isLoading?: boolean
  user?: Member | null
  accessToken?: string | null
  setUser: (
    user?: Member,
    isLoading?: boolean,
    isAuthenticated?: boolean,
  ) => void
  setAccessToken: (accessToken: string, expiresIn: number) => void
}

export const useAuth = create<UserState>(set => ({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  accessToken: null,
  setUser: (
    user?: Member,
    isLoading?: boolean,
    isAuthenticated?: boolean,
    accessToken?: string,
  ) => set({ user: user, isLoading, accessToken, isAuthenticated }),

  updateNotificationPreferences: (prefs: any) =>
    set(state => ({
      ...state,
      user: { ...state.user!, notificationPreferences: prefs },
    })),

  setAccessToken: (accessToken: string, expiresIn: number) =>
    set(state => {
      localStorage.setItem('accessToken', accessToken)

      return {
        ...state,
        accessToken,
      }
    }),
}))

// mountStoreDevtool('auth', useAuth as any);
