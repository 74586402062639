import { useRouter } from 'next/router'
import { useRoot } from 'store/useRoot'
import { useAuth } from '../store/useAuth'
import api from '../utils/api'

export function useFetchProfile() {
  const setUser = useAuth(state => state.setUser)

  const root = useRoot()

  const router = useRouter()

  const fetch = () => {
    api
      .get('/profile')
      .then(result => {
        if (result.data.data?.initial_reg?.status === 'started') {
          // if (window.location.pathname !== '/initial') {
          //   router.replace('/initial')
          // }

          root.setInitialReg(true)
          root.setDisableNav(true)
        }

        setUser(result.data, false, true)
      })
      .catch(err => {
        setUser(undefined, false, false)
      })
  }

  return { fetch }
}
