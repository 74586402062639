import { realtyBrandConfig } from './tenant-brand-config'

export enum TENANTS {
  realty = 'realty',
  homescout = 'homescout',
  morganti_group = 'morganti_group',
  listing_com = 'listing_com',
  drmoves = 'drmoves',
  mellohome = 'mellohome',
  realty_ca = 'realty_ca',
  exp_realty = 'exp_realty',
  coachpipes = 'coachpipes',
  datahub = 'datahub',
  movetube = 'movetube',
}

const CENTRAL_SITE = 'https://portal.realty.com'

const CENTRAL_SITE2 = 'https://realty.realtyhs.net'

const CENTRAL_SITE3 = 'https://portal.realty.ca'

const CENTRAL_SITE4 = 'https://portal.listing.com'

const CENTRAL_SITE5 = 'https://pipesportal.realty.com'

const tenantMainSite: any = {
  'http://localhost:3000': 'https://www.realty.com',
  'https://portal.realty.com': 'https://www.realty.com',
  'https://realty.realtyhs.net': 'https://www.realty.com',
  'https://homescoutdash.com': 'https://lenderpro.realty.com',
  'https://morganti.realtylogin.net': 'https://lasvegashomes.realty.com',
  'https://drmoves.realtylogin.net': 'https://drmoves.realty.com',
  'https://portal.listing.com': 'https://www.listing.com',
  'https://mellohome.realtylogin.net': 'https://mellohome.realty.com',
  'https://portal.realty.ca': 'https://www.realty.ca',
  'https://exprealty.realtylogin.net': 'https://exprealty.realty.com',
  'https://pipesportal.realty.com': 'https://coachpipes.realty.com',
  'https://datahubportal.realty.com': 'https://datahub.realty.com',
  'https://movetubeportal.realty.com': 'https://movetube.realty.com',
}

const tenantName: any = {
  'http://localhost:3000': 'realty',
  'https://portal.realty.com': 'realty',
  'https://realty.realtyhs.net': 'realty',
  'https://homescoutdash.com': 'homescout',
  'https://morganti.realtylogin.net': 'morganti_group',
  'https://drmoves.realtylogin.net': 'drmoves',
  'https://portal.listing.com': 'listing_com',
  'https://mellohome.realtylogin.net': 'mellohome',
  'https://portal.realty.ca': 'realty_ca',
  'https://exprealty.realtylogin.net': 'exp_realty',
  'https://pipesportal.realty.com': 'coachpipes',
  'https://datahubportal.realty.com': 'datahub',
  'https://movetubeportal.realty.com': 'movetube',
}

export const tenantSwitcherMap = {
  NO_TENANT: 'https://portal.realty.com',
  homescout: 'https://homescoutdash.com',
  morganti_group: 'https://morganti.realtylogin.net',
  drmoves: 'https://drmoves.realtylogin.net',
  listing_com: 'https://portal.listing.com',
  mellohome: 'https://mellohome.realtylogin.net',
  realty_ca: 'https://portal.realty.ca',
  exp_realty: 'https://exprealty.realtylogin.net',
  coachpipes: 'https://pipesportal.realty.com',
  datahub: 'https://datahubportal.realty.com',
  movetube: 'https://movetubeportal.realty.com',
}

export const tenantDbToOrginMap = {
  NO_TENANT: ['https://portal.realty.com', 'https://realty.realtyhs.net'],
  homescout: ['https://homescoutdash.com'],
  morganti_group: ['https://morganti.realtylogin.net'],
  drmoves: ['https://drmoves.realtylogin.net'],
  listing_com: ['https://portal.listing.com'],
  mellohome: ['https://mellohome.realtylogin.net'],
  realty_ca: ['https://portal.realty.ca'],
  exp_realty: ['https://exprealty.realtylogin.net'],
  coachpipes: ['https://pipesportal.realty.com'],
  datahub: ['https://datahubportal.realty.com'],
  movetube: ['https://movetubeportal.realty.com'],
}

export const tenantCountryCode: any = {
  NO_TENANT: 'US',
  homescout: 'US',
  morganti_group: 'US',
  drmoves: 'US',
  listing_com: 'US',
  mellohome: 'US',
  realty_ca: 'CA',
  exp_realty: 'US',
  coachpipes: 'US',
  datahub: 'US',
  movetube: 'US',
}

export const getTenantCountryCode = () => {
  return tenantCountryCode[getTenantName()]
}

export const getUSCADomain = () => {
  return getTenantName() === 'realty' ? 'Realty.com' : 'Realty.ca'
}

const czEmailDomain = {
  realty: 'Realty.com',
  realty_ca: 'Realty.ca',
  listing_com: 'Listing.com',
  coachpipes: 'CoachPipes.Realty.com',
}
export const getCZEmailDomain = () => {
  const domain = czEmailDomain[getTenantName()]

  if (domain) {
    return domain
  }

  return 'NoDomain.com'
}

export const getLocalTenant = () => {
  return localStorage.getItem('tenant') ?? 'NO_TENANT'
}

const isDev = process.env.NODE_ENV === 'development'

export const isMainSite = (): boolean => {
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }

  if (
    origin === CENTRAL_SITE ||
    origin === 'http://localhost:3000' ||
    origin === CENTRAL_SITE2 ||
    origin === CENTRAL_SITE3 ||
    origin === CENTRAL_SITE4 ||
    origin === CENTRAL_SITE5
  ) {
    return true
  }

  return false
}

export const getTenantKeyFromOrgin = (): string => {
  let origin = window?.location?.origin

  if (isDev) {
    return getLocalTenant()
  }

  let tenantKey: string | undefined

  Object.entries(tenantDbToOrginMap).forEach(([key, value]) => {
    if (value.includes(origin)) {
      tenantKey = key
    }
  })

  return tenantKey
}

export const getTenantName = (): string => {
  if (typeof window == 'undefined') {
    return
  }
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }

  return tenantName[origin]
}

export const getTenantMetaData = (): { title: string } => {
  let origin = 'http://localhost:3000'
  if (typeof window !== 'undefined') {
    origin = window?.location?.origin

    if (isDev) {
      origin = tenantSwitcherMap[getLocalTenant()]
    }
  }

  return realtyBrandConfig[tenantName[origin]].metaData
}

export const getTenantMainSite = (): string => {
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }
  return tenantMainSite[origin]
}

export const getCdnImage = (data: {
  entity: string
  width?: number
}): string => {
  const { entity, width } = data
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }

  return `${tenantMainSite[origin]}/imagecdn/${entity}${
    width ? `?w=${width}` : ''
  }`
}

export const getMemberProfileImageUrl = (data: {
  memberId: number
  profileImage: string
  width: 100
}): string => {
  const { memberId, profileImage, width } = data
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }

  return `${tenantMainSite[origin]}/imagecdn/members/${memberId}/${profileImage}?w=${width}`
}

export const getUnClaimedAgentImageUrl = (data: {
  agentId: number
  profileImage: string
  width: 100
}): string => {
  const { agentId, profileImage, width } = data
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }

  return `${tenantMainSite[origin]}/imagecdn/unclaimed_agents/${agentId}/${profileImage}?w=${width}`
}

export const getGroupMemberProfileImageUrl = (data: {
  groupId: number
  profileImage: string
  width: number
}): string => {
  const { groupId, profileImage, width } = data
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }

  return `${tenantMainSite[origin]}/imagecdn/groups/${groupId}/${profileImage}?w=${width}`
}

export const getGroupCoverImage = (data: {
  groupId: number
  coverImage: string
}): string => {
  const { groupId, coverImage } = data
  if (!coverImage) return ''

  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }
  return `${tenantMainSite[origin]}/imagecdn/groups/${groupId}/${coverImage}?`
}

export const getGroupArticleImage = ({
  groupId,
  articleImage,
}: {
  groupId: number
  articleImage: string
}): string => {
  if (!articleImage) return ''

  const origin: string = isDev
    ? tenantSwitcherMap[getLocalTenant()]
    : window?.location?.origin || ''

  return `${tenantMainSite[origin]}/imagecdn/groups/${groupId}/article_images/${articleImage}?`
}

export const getLeadAttachmentUrl = (data: {
  leadId: number
  media: string
}): string => {
  const { leadId, media } = data
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }
  //if the media is an external link then return the media as is
  if (media?.includes('http')) {
    return media
  }
  return `${tenantMainSite[origin]}/imagecdn/leads/${leadId}/${media}?`
}

export const getArticleImage = (data: {
  type: string
  coverImage: string
}): string => {
  const { type, coverImage } = data
  let origin = window?.location?.origin

  if (isDev) {
    origin = tenantSwitcherMap[getLocalTenant()]
  }
  return `${tenantMainSite[origin]}/imagecdn/articles/${type}/${coverImage}?`
}
