let URL = ''

if (typeof window !== 'undefined') {
  URL = window.location.hostname
}

if (process.env.NODE_ENV == 'development') {
  URL = 'localhost:6500'
}
// if (process.env.REACT_APP_STAGE === 'staging') {
//   URL = 'portal-staging.realty.com';
// }

export { URL }
