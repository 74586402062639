import axios from 'axios'
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig,
} from 'axios-auth-refresh'
import { getLocalTenant, tenantSwitcherMap } from 'tenant'

import { URL } from '../config'
//
export const url =
  process.env.NODE_ENV === 'development'
    ? `http://${URL}/api/v2`
    : `https://${URL}/api/v2`

const api = axios.create({
  baseURL: url,
  withCredentials: true,
})

const handleRefreshFailed = async (token?: boolean | false) => {
  if (token) {
    localStorage.removeItem('accessToken')
  }
  const whitelist = ['/', '/auth/login', '/auth/reset-password/']
  if (!whitelist.some(route => window.location.pathname.includes(route))) {
    window.location.assign('/')
  }
  return
}

export const getAccessToken = () => {
  return localStorage.getItem('accessToken')
}

export const getDomain = () => {
  return tenantSwitcherMap[getLocalTenant()]
}

const requestNewToken = async () => {
  try {
    const response = await api.get('/oauth/token', {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig)
    return response.data
  } catch (error: any) {}
}

//  Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest: any) => {
  //token-expired - inactivity - refresh
  //a
  if (failedRequest && failedRequest.response) {
    const token = await requestNewToken()

    if (token) {
      localStorage.setItem('accessToken', token.accessToken)
      failedRequest.response.config.headers['x-api-token'] = token.accessToken
    } else {
      handleRefreshFailed(true)
    }
  } else {
    handleRefreshFailed()
  }
}

createAuthRefreshInterceptor(api, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
})

//this is to retry calls that were not included into the retry stack of the createAuthRefreshInterceptor
api.interceptors.response.use(
  response => response,
  async error => {
    const request = error.config

    if (
      (request as AxiosAuthRefreshRequestConfig) &&
      (request as AxiosAuthRefreshRequestConfig).skipAuthRefresh
    )
      throw error
    // token expire headers must be used
    if (error && error.response && error.response.status === 401) {
      //let's retry
      return await api(request)
    }

    throw error
  },
)

// Use interceptor to inject the token to requests
api.interceptors.request.use(request => {
  if (
    (request as AxiosAuthRefreshRequestConfig) &&
    (request as AxiosAuthRefreshRequestConfig).skipAuthRefresh
  )
    return request
  const accessToken = getAccessToken()
  request.headers['x-api-token'] = accessToken

  if (process.env.NODE_ENV === 'development') {
    request.headers['x-api-domain'] = getDomain()
  }

  return request
})

export default api
