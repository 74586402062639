import '../styles/globals.css'
import 'react-medium-image-zoom/dist/styles.css'
import 'nprogress/nprogress.css'

import React, { FC, useEffect } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import Cookie from 'js-cookie'
import { ConfirmProvider } from 'material-ui-confirm'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import nProgress from 'nprogress'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import type { EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { load as loadFingerprintJS } from '@fingerprintjs/fingerprintjs'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import useToast from '../hooks/use-toast'
import { useVerifyAuth } from '../hooks/useVerifyAuth'
import { useSettings } from '../store/useSettings'
import { getTenantMetaData } from '../tenant'
import { createTheme } from '../theme'
import { createEmotionCache } from '../utils/create-emotion-cache'

LicenseInfo.setLicenseKey(
  '40d81714bc5bdb72b15a266a5c9590a5T1JERVI6MzY3MjQsRVhQSVJZPTE2NzQ4ODAwMzIwMDAsS0VZVkVSU0lPTj0x',
)

type EnhancedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const delay = 500 // in milliseconds
let timer
const load = () => {
  timer = setTimeout(function () {
    nProgress.start()
  }, delay)
}
const stop = () => {
  clearTimeout(timer)
  nProgress.done()
}

Router.events.on('routeChangeStart', () => nProgress.start())
Router.events.on('routeChangeComplete', () => nProgress.done())
Router.events.on('routeChangeError', () => nProgress.done())
nProgress.configure({ showSpinner: false, easing: 'ease', speed: 800 })

const clientSideEmotionCache = createEmotionCache()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
})

const MyApp: FC<EnhancedAppProps> = props => {
  const { isLoading } = useVerifyAuth()

  const settings = useSettings()
  const { notify, notifyError } = useToast()

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const getLayout = Component.getLayout ?? (page => page)

  const getBrowserId = async () => {
    const browserId = Cookie.get('deviceId')
    if (browserId) {
      return browserId
    }
    const fp = await loadFingerprintJS()
    const data = await fp.get()
    Cookie.set('deviceId', data.visitorId)
    return data
  }

  useEffect(() => {
    getBrowserId().then(data => {
      //
    })
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>{getTenantMetaData().title}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>

        <ThemeProvider
          theme={createTheme({
            direction: settings.direction,
            responsiveFontSizes: settings.responsiveFontSizes,
            mode: settings.theme,
          })}
        >
          <CssBaseline />
          <Toaster
            position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
          />
          <CssBaseline />

          <ConfirmProvider>
            <AnimatePresence>
              {isLoading ? (
                <motion.div
                  key="loading"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  style={{ position: 'absolute' }}
                ></motion.div>
              ) : (
                <motion.div
                  key="content"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {!isLoading && getLayout(<Component {...pageProps} />)}
                </motion.div>
              )}
            </AnimatePresence>
          </ConfirmProvider>
        </ThemeProvider>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </CacheProvider>
  )
}

export default MyApp
