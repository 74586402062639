import create from 'zustand'

interface RootState {
  disableNav: boolean
  setDisableNav: (val: boolean) => void

  initialReg: boolean
  setInitialReg: (val: boolean) => void
}

export const useRoot = create<RootState>(set => ({
  disableNav: false,

  initialReg: false,

  setDisableNav: (val: boolean) => {
    return set(state => ({ ...state, disableNav: val }))
  },

  setInitialReg: (val: boolean) => {
    return set(state => ({ ...state, initialReg: val }))
  },
}))
