import create from 'zustand'
import { persist } from 'zustand/middleware'

interface Translation {
  config: {} | null
  setTranslation: (translation: Translation) => void
}
const defaultTranslation = {
  auth: {
    email: 'Email',
    login: 'Login',
    log_in: 'Log In',
    submit: 'Submit',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
  },
  toasts: {
    passwordRequestRecieved: 'Your password reset was received.',
  },
  sidebar: {
    menus: {
      leads: 'Leads',
      roles: 'Roles',
      brands: 'Brands',
      groups: 'Groups',
      account: 'Account',
      members: 'Users',
      onboard: 'Onboard',
      settings: 'Settings',
      dashboard: 'Dashboard',
      activities: 'Activities',
      translation: 'i18n',
      integrations: 'Integrations',
    },
    titles: {
      global: 'Global',
      general: 'change hoga ab ye',
      management: 'Manage',
    },
    submenus: {
      wizard: 'Wizard',
      requests: 'Requests',
      inquiries: 'Inquiries',
      brandsList: 'List',
      groupsList: 'List',
      membersList: 'List',
      brandsCreate: 'Create',
      membersCreate: 'Create',
      savedSearches: 'Saved Searches',
    },
  },
  validation: {
    isRequired: 'is required!',
    validEmail: 'Must be a valid email.',
    invalidEmail: 'Invalid email',
  },
}
export const useTranslation = create<Translation>(
  persist(
    (set, get) => ({
      config: defaultTranslation,
      setTranslation: (translation: Translation) =>
        set({
          config: translation,
        }),
    }),
    {
      name: 'translation',
    },
  ),
)

// mountStoreDevtool('settings', useSettings as any);
